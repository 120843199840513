.rpb-hero {
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		left: 2%;
		top: 4%;
		height: 92%;
		width: 96%;
		border: 3px solid #8ab4de;
		z-index: 10;
		border-radius: 12px;
		box-shadow: 0 0 32px rgba(0,0,0,0.5);
		@include media-breakpoint-up(xl) {
			border: 6px solid #8ab4de;
		}
	}
	.hero-subtitle {
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: 3px;
		margin: 1rem 0 .5rem;
	}
	.hero-content {
		position: relative;
		z-index: 100;
		.five-star {
			i,svg {
				color: $accent;
				font-size: 24px;
				margin: 0 7px;
			}
		}
		.h1.hero-title {
			color: #fff;
			font-size:2.15rem;
			@include media-breakpoint-up(xl) {
					font-size:4.5rem;
			}
			span {
				color:$brand-primary;
				display: block;
				font-size: 22px;
			  text-transform: uppercase;
			  background: none;
				letter-spacing: 15px;
 			  margin: 0 0 0 5px;
				background: none;
				-webkit-text-fill-color: $brand-primary;
				@include media-breakpoint-up(xl) {
					font-size: 48px;
					letter-spacing: 28px;
					margin: -25px 0 0 20px;
				}
			}
		}
		.line-break {
			background: #545454;
			height: 5px;
			width: 125px;
			display: block;
			margin: 1.5rem auto;
		}
	}
}

.rpb-simple-content {
	.hc-half-content-image {
		box-shadow: 0 0 18px rgba(0,0,0,0.15);
		.featured-content {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background: #fff;
			border-radius: 0 0 22px 22px;
		}
	}
}

.home-services {
	position: relative;
	z-index: 100;
	@include media-breakpoint-up(xl) {
		margin-top: -100px;
	}
	.rpb-cards {
		.card {
			position: relative;
			border-radius:24px;
			.card-img-top {
				border-radius: 24px;
			  min-height: 225px;
			}
			.card-header {
				padding-top: 12%;
				@include media-breakpoint-up(xl) {
						padding-top: 48%;
				}
			}
			.card-footer {
					position: absolute;
					right: 10%;
					bottom: 21%;
					color: #fff;
					border: 2px solid rgba(255,255,255,0.35);
					border-radius: 8px;
					height: 50px;
					width: 50px;
					text-align: center;
					font-size: 32px;
					transition: all ease-in 0.25s;
					i,svg {
						transition: all ease-in-out 0.35s;
						margin-left: -35px;
					}
	  		}
				&:hover {
					.card-footer {
						background: rgba(255,255,255,0.35);
						i,svg {
							margin-left: 18px;
						}
					}
				}
    	}
		}
}

.subtitle {
	color: $brand-primary;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 3px;
	margin: 1rem 0 .5rem;
}

.first-content-section {
	margin-top: -75px;
	.five-star {
		i,svg {
			color:  $dark;
			font-size: 24px;
			margin: 0 7px;
		}
	}
	.hc-half-content-image {
		border-radius:24px;
	}
	.home-special-offer {
		position: relative;
		z-index: 100;
		margin-bottom: -65px;
		@include media-breakpoint-up(xl) {
				margin-bottom: -75px;
		}
		.inner-content {
			box-shadow: 0 0 16px rgba(0,0,0,0.75);
			.special-title {
				text-transform: uppercase;
				font-size: 2.5rem;
				letter-spacing: 2px;
				@include media-breakpoint-up(xl) {
						font-size: 4.5rem;
						letter-spacing: 7px;
				}
			}
			hr {
				background: #fff;
				opacity: 0.35;
			}
			.home-tech {
				position: absolute;
				right: 4.5rem;
				bottom: 0;
			}
		}
	}
}

.second-content-section {
	.hc-half-content-image {
		position: relative;
		border-radius: 22px;
	}
}

.home-icon-section {
	margin: -50px 0 0;
	@include media-breakpoint-up(xl) {
		margin: -50px 0 -50px;
	}
	.rpb-cards {
     .card {
			 transition: all 0.5s;
			 border-radius: 22px;
			 &:hover {
				 box-shadow: 0 0 32px rgba(0,0,0,0.2);
			 }
		 }
	}
	.card-icon-wrap {
		background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary, 15%) 100%);
		height: 60px;
		width: 60px;
		border-radius: 6px;
		font-size: 32px;
		text-align: center;
		padding: 15px 0;
		display: block;
		margin:  -30px auto 0;
		i,svg {
			color:#fff;
		}
	}
}

.home-footer-topper {
	.card {
		min-height: 250px;
		.card-header {
			padding-top: 24%;
			@include media-breakpoint-up(xl) {
				padding-top: 50%;
			}
		}
	}
}

.footer-brands {
	.card {
		img.brand-logo{
			max-height: 145px;
		}
	}
}

.template-homepage {
 .page-cta-section{
		display: none;
	}
}

.rpb-customer-reviews {
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(185,210,235,0) 50%,rgba(138,180,222,0.25) 100%);
	.location-single-review {
		 border-radius: 22px;
		 box-shadow: 0 0 16px rgba(0,0,0,0.15);
	}
}

.footer-contact-us {
	position: relative;
	&:before {
		content:'';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 0;
		background: linear-gradient(rgba(0, 34, 68, .5), rgba(0, 34, 68, .7));
	}
	.contact-form-wrap {
		border-radius: 22px;
		margin: 0 0 -50px auto;
		@include media-breakpoint-up(xl) {
			margin: -50px 0 -50px auto;
		}
		input,textarea, .ginput_container_textarea {
			background: #fff;
			border: none!important;
			border-radius: 6px!important;
			padding: 12px 8px!important;
		}
		#gform_submit_button_1 {
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.45)!important;
			color:#fff!important;
			background: $accent!important;
			padding: 0.75rem 1.5rem!important;
			font-size: 1em!important;
		}
	}
	.column-content {
		position: relative;
		z-index: 100;
	}
}
