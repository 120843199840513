/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_wrapper.gravity-theme .gfield input, .gform_wrapper.gravity-theme .gfield select, textarea {
  background: rgba(255,255,255,0.5)!important;
  border: 1px solid #9e9e9e!important;
  outline: none!important;
  border-radius: 3px!important;
}

.gform_button.button {
  background: $accent;
  border: 3px solid $accent;
  border-radius: 6px;
  color: #fff;
  font-size: auto;
  font-weight: 600;
  line-height: 1;
  padding: .75rem 1.5rem;
  text-align: center;
}
